<template>
  <ModalConfirm
    ref="modal"
    class="create-modal"
    max-width="660px"
    :disabled="invalid"
  >
    <p class="create-modal__instructions">
      You are about to create an additional merchant account for <strong>{{ clubName }}</strong>.
    </p>
    <p class="create-modal__instructions">
      Are you sure you want to proceed?
    </p>
    <b-field v-if="!primaryAccountName">
      <template #label><span class="create-modal__field-label">Existing Merchant Account Name</span></template>
      <b-input v-model="newPrimaryAccountName" :maxlength="NAME_MAX_LENGTH" />
    </b-field>
    <b-field>
      <template #label><span class="create-modal__field-label">New Merchant Account Name</span></template>
      <b-input v-model="newAccountName" :maxlength="NAME_MAX_LENGTH" />
    </b-field>
  </ModalConfirm>
</template>

<script setup>
import { isEmpty, get } from 'lodash';
import { computed, defineEmits, defineExpose, ref } from 'vue';
import ModalConfirm from '@/components/layout/modals/ModalConfirm';

const emit = defineEmits(['add']);

const modal = ref(null);
const club = ref(null);
const existingMerchantAccounts = ref([]);
const clubName = computed(() => club.value?.name);
const primaryAccount = computed(() => existingMerchantAccounts.value.find(ma => ma.is_default));
const primaryAccountName = computed(() => get(primaryAccount.value, 'name', ''));

const NAME_MAX_LENGTH = 20;
const newPrimaryAccountName = ref('');
const newAccountName = ref('');

const invalid = computed(() => (
  isEmpty(newAccountName.value)
  || (isEmpty(primaryAccountName.value) && isEmpty(newPrimaryAccountName.value))
));

function confirmAdd() {
  emit('add', {
    newPrimaryAccountName: newPrimaryAccountName.value,
    newAccountName: newAccountName.value,
  });
}

function showModal(inClub, inExistingAccounts) {
  club.value = inClub;
  existingMerchantAccounts.value = inExistingAccounts;

  newPrimaryAccountName.value = '';
  newAccountName.value = '';

  modal.value.showModal({
    title: 'Create Additional Club Merchant Account',
    icon: 'check-circle',
    pack: 'far',
    confirmText: 'Continue',
    onConfirm: confirmAdd,
  });
}

defineExpose({ showModal });
</script>

<style scoped lang="sass">
.create-modal
  &__instructions
    margin-bottom: $space-6
  &__field-label
    font-family: $family-headers
    font-weight: $weight-semibold
</style>
